<template>
    <v-app>
        <div>
            <Common-Home-Header :callpermissionflag="callpermissionflag" :authDialogue="auth_error_enable" :errorDialogue="error_enable"></Common-Home-Header>
            <div class="headers-tab">
                <v-divider></v-divider>
                <Header-Tabs-Component :tab_id="1" :is_user_logged_in="false"></Header-Tabs-Component>
            </div>
            
            <div v-if="isDesktopMode" class="forWeb">
                <div class="fullWebPage">
                    <div class="container webContainer rounded-lg">
                        <div class="webHeading">
                            <p class="bold medFont">
                                {{ $t('Customer.MyOrders.My_Orders') }}
                            </p>
                        </div>
                        
                         <!-- Skeleton Loader Web -->
                        <div v-if="showSkeletonLoader" v-for="i in 5" class="skeletonLoader">
                            <div class="one">
                                <div class="leftSideInfo1">
                                    <v-skeleton-loader
                                        type="text"
                                    ></v-skeleton-loader>
                                    <v-skeleton-loader
                                        width="50%"
                                        type="text"
                                    ></v-skeleton-loader>
                                    <v-skeleton-loader
                                        width="70%"
                                        type="text"
                                    ></v-skeleton-loader>
                                </div>
                                <div class="rightSideInfo1">
                                    <v-skeleton-loader
                                        class="d-flex justify-end"
                                        width="40%"
                                        type="text"
                                    ></v-skeleton-loader>
                                    <v-skeleton-loader
                                        class="d-flex justify-center"
                                        width="40%"
                                        type="button"
                                    ></v-skeleton-loader>
                                </div>
                            </div>
                            <v-divider style="width: 95%; margin-left: 2.5%;"></v-divider>
                            <div class="two">
                                <div class="leftSideInfo2">
                                    <v-skeleton-loader
                                        type="list-item"
                                    ></v-skeleton-loader>
                                </div>
                                <div class="rightSideInfo2">
                                    <v-skeleton-loader
                                        type="list-item"
                                        width="50%"
                                    ></v-skeleton-loader>
                                </div>
                            </div>
                        </div>
                        <!-- Web Component -->
                        <div class="webNoOrders" v-if="ordersList.length==0">
                            <img width="110px" height="110px"
                                src="https://s3iconimages.mymedicine.com.mm/noOrdersImage.svg" 
                                alt="No Orders Found"
                            >
                            <p class="my-0 title-heading font-weight-semi-bold dark-grey-color-text">{{ $t('Customer.MyOrders.No_Orders_Found') }}</p>
                            <p class="body-heading font-weight-medium light-grey-color-text">{{ $t('Customer.MyOrders.Fill_it_up_with_your_favorite_products') }}</p>
                        </div>
                        <div v-else class="ordersList" v-for="order in ordersList">
                            <div v-if="!showSkeletonLoader">
                                <Particular-Order-Component-Web
                                    :order="order"
                                    :showSkeletonLoader="showSkeletonLoader"
                                >
                                </Particular-Order-Component-Web>
                            </div>
                        </div>
                    </div>
                </div>
                
                <v-divider></v-divider>

                <div class="pa-4">
                    <Common-Home-Footer></Common-Home-Footer>
                </div>
            </div>
            <div v-if="!isDesktopMode" class="container">
                <v-divider style="border: 1px solid #E0E0E0; margin-top: 10px;"></v-divider>
                <div class="mobileHeading">
                    <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="Back Button" class="clickable-pointer" @click="goToPreviousPage()"/>
                    <p class="bold medFont ma-0 mx-2">
                        {{ $t('Customer.MyOrders.My_Orders') }}
                    </p>
                </div>
                <v-divider style="border: 1px solid #E0E0E0; margin-bottom: 20px;"></v-divider>

                <!-- Skeleton Loader Mobile -->
                <div v-if="showSkeletonLoader" v-for="i in 5" class="skeletonLoader">
                    <div class="one">
                        <div class="leftSideInfo1">
                            <v-skeleton-loader
                                type="text"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                width="50%"
                                type="text"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                width="70%"
                                type="text"
                            ></v-skeleton-loader>
                        </div>
                        <div class="rightSideInfo1">
                            <v-skeleton-loader
                                class="d-flex justify-end"
                                width="40%"
                                type="text"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                class="d-flex justify-center"
                                width="40%"
                                type="button"
                            ></v-skeleton-loader>
                        </div>
                    </div>
                    <v-divider style="width: 95%; margin-left: 2.5%;"></v-divider>
                    <div class="two">
                        <div class="leftSideInfo2">
                            <v-skeleton-loader
                                type="list-item"
                            ></v-skeleton-loader>
                        </div>
                        <div class="rightSideInfo2">
                            <v-skeleton-loader
                                type="list-item"
                                width="50%"
                            ></v-skeleton-loader>
                        </div>
                    </div>
                </div>
                <!-- Mobile Component -->
                <div class="mobileNoOrders" v-if="ordersList.length==0">
                    <img width="80px" height="80px"
                        src="https://s3iconimages.mymedicine.com.mm/noOrdersImage.svg" 
                        alt="No Orders Found"
                    >
                    <p class="my-0 title-heading font-weight-semi-bold dark-grey-color-text">{{ $t('Customer.MyOrders.No_Orders_Found') }}</p>
                    <p class="body-heading font-weight-medium light-grey-color-text">{{ $t('Customer.MyOrders.Fill_it_up_with_your_favorite_products') }}</p>
                </div>
                <div v-else class="ordersList" v-for="order in ordersList">
                    <Particular-Order-Component-Mobile
                        :order="order"
                    >
                    </Particular-Order-Component-Mobile>
                </div>
            </div>
        </div>
        <div v-if="!isDesktopMode" class="bottomNavBar">
            <Bottom-Nav-Bar-Component highlight_icon="my_orders"></Bottom-Nav-Bar-Component>
        </div>
    </v-app>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import axios from "axios";
export default {
    name: "MyOrdersPage",
    components: {
        'Common-Home-Header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
        'Common-Home-Footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
        'Particular-Order-Component-Web': () => import('../../../components/ePharmacyComponents/orderWebComponent.vue'),
        'Particular-Order-Component-Mobile': () => import('../../../components/ePharmacyComponents/orderMobileComponent.vue'),
        'Bottom-Nav-Bar-Component': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        'Header-Tabs-Component': () => import('../../../components/navigationComponents/headerTabsComponent.vue')
    },
    data() {
        return {
            ordersList: [],
            showSkeletonLoader: true,
            isDesktopMode: false,
            currentCustomer: null,
            callpermissionflag: false,
            error_enable: false,
            auth_error_enable: false,
            token: null
        }
    },
    mounted() {
        if (window.xm){
      		document.title = '';
            this.token = localStorage.getItem('customerToken');
    	} else {
			document.title = 'Orders';
            this.token = this.$cookies.get('customerToken');
		}
        if (this.$store.state.locale=='') {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
        else
            this.$i18n.locale = this.$store.state.locale;


        this.checkScreenMode();
        window.addEventListener("resize",  this.checkScreenMode);
        this.currentCustomer = this.$cookies.get("customerToken");
        if(!this.currentCustomer) {
            if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
        } else {
            axios_auth_instance_epharmacy_customer.get("/orders/listAllOrder"
            ).then((getAllMyOrdersResponse) => {

                this.ordersList = getAllMyOrdersResponse.data.data.orderList;
                this.showSkeletonLoader = false;
                this.callpermissionflag = true;

            }).catch((getAllMyOrdersError) => {
                
                if (getAllMyOrdersError.status === 400) {
                    console.log('Error in getting AllMyOrders');
                } else if (getAllMyOrdersError.status === 401) {
                    this.$cookies.remove('customerToken');
				    this.auth_error_enable = true;
                } else {
                    console.log('getAllMyOrdersError: ', getAllMyOrdersError);
                    this.error_enable = true;
                }
            });
            this.userPageViewTable();
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenMode);
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'myOrdersPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'myOrdersPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        goToPreviousPage() {
            window.history.back();
        },
        checkScreenMode() {
            this.isDesktopMode = window.matchMedia("(min-width: 601px)").matches;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';
.webNoOrders {
    padding-top: 50px;
    padding-bottom: 50px;
}
.mobileNoOrders {
    padding-top: 120px;
}
.headers-tab {
    @media (max-width: 600px) {
        display: none;
	}
 }
 
.leftSideInfo2 {
    width: 50%;
}
.rightSideInfo2 {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.leftSideInfo1 {
    padding: 16px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.rightSideInfo1 {
    padding: 16px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}
.two {
    display: flex;
}
.one {
    display: flex;
    height: 100px;
}
.skeletonLoader {
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    @media (max-width: 600px) {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    @media (min-width: 601px) {
        margin-top: 10px;
        margin-bottom: 30px;
    }
}
.bottomNavBar {
    bottom: 0;
	position: sticky;
	z-index: 999;
}
.bigFont {
    font-size: 20px;
}
.medFont {
    font-size: 18px;
}
.bold {
    font-weight: 600;
}
.ordersList {
    padding-top: 10px;
    padding-bottom: 20px;
}
.mobileHeading {
    display: flex;
    justify-content: left;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
}
.webHeading {
    text-align: left;
    padding-bottom: 20px;
    line-height: 30px;
}
.webContainer {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 32px;
    display: inline-block;
    background-color: white;
}
.fullWebPage {
    background-color: #F4F6FA;
    padding-left: 50px;
    padding-right: 50px;
}
</style>